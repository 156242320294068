<template>
  <div class="container mt-2">
    <!-- <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template> -->
    <b-row>
      <b-col
        cols="6"
      ><div>
        <h4>
          <router-link :to="{ name: 'lottery' }">
            <i class="fas fa-chevron-circle-left fa-lg" />
          </router-link>
          ย้อนกลับ
        </h4>
      </div></b-col>
      <!-- <b-col cols="6">
        <div class="d-flex align-items-center justify-content-end">
          <a
            class="cta"
            href="#num_check"
          >
            <div>
              <span>เลขอั้น เลขปิดรับ</span>
              <span>
                <svg
                  width="34px"
                  height="13px"
                  viewBox="0 0 66 43"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="arrow"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path
                      class="one"
                      d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                      fill="#FFFFFF"
                    />
                    <path
                      class="two"
                      d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                      fill="#FFFFFF"
                    />
                    <path
                      class="three"
                      d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                      fill="#FFFFFF"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </a>
        </div>
      </b-col> -->
    </b-row>

    <!-- Lotto THAI -->
    <div class="header-bg-content mb-2">
      <b-row class="align-items-center">
        <b-col
          lg="6"
          md="6"
          cols="6"
        >
          <h4
            class="align-items-center topic"
          >
            <img
              src="/country/th.svg"
              alt="country-th"
              class="img-country"
            >
            {{ Title.LottoName }}
          </h4></b-col>
        <b-col
          lg="6"
          md="6"
          cols="6"
        >
          <div class="justify-content-end">
            <span class="time">
              <i class="far fa-clock" /> {{ Title.ShowCloseDate }}
            </span>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- ตัวเลือกหวย -->
    <div class="mb-2">
      <b-row class="header-bg-content2">
        <b-col
          v-if="
            Title.LottoDetail.u3 ||
              Title.LottoDetail.d3 ||
              Title.LottoDetail.t3
          "
          lg="4"
          md="4"
          cols="4"
          class="p-0"
        >
          <div>
            <button
              :class="`btn-type3 btnp ${ShowDigit === 3 ? 'active' : ''}`"
              @click="SelectDigit(3)"
            >
              หวย 3 ตัว
            </button>
          </div>
        </b-col>
        <b-col
          v-if="
            Title.LottoDetail.u2 ||
              Title.LottoDetail.d2 ||
              Title.LottoDetail.t2
          "
          lg="4"
          md="4"
          cols="4"
          class="p-0"
        >
          <div>
            <button
              :class="`btn-type2 btnp ${ShowDigit === 2 ? 'active' : ''}`"
              @click="SelectDigit(2)"
            >
              หวย 2 ตัว
            </button>
          </div>
        </b-col>
        <b-col
          v-if="Title.LottoDetail.u1 || Title.LottoDetail.d1"
          lg="4"
          md="4"
          cols="4"
          class="p-0"
        >
          <div>
            <button
              :class="`btn-type1 btnp ${ShowDigit === 1 ? 'active' : ''}`"
              @click="SelectDigit(1)"
            >
              เลขวิ่ง
            </button>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="row match-height pdd">
      <div class="col-3 bg-side">
        <div class="">
          <b-button
            v-b-toggle.sidebar-footer
            block
            size="sm"
            variant="danger"
          >
            <feather-icon icon="EditIcon" /> ใส่ราคา
          </b-button>
          <b-button
            block
            size="sm"
            variant="info"
            @click="Undo()"
          >
            <i class="far fa-hand-point-left" /> ย้อนกลับ
          </b-button>
          <div class="text-center">
            <small
              class="text-danger font-weight-bolder text-center"
            >รายการแทง</small>
            <hr>

            <div style="margin-top: -12px">
              <span>{{ items.length }} รายการ</span>
            </div>
          </div>
          <div style="margin-top: 5px">
            <div v-if="ShowGroupList('3ตัวบน')">
              <p>สามตัวบน</p>
              <p
                v-for="(ListNum,IndexNum) in ShowGroupList('3ตัวบน')"
                :key="ListNum.num_lot"
              >
                {{ IndexNum+1 }}. {{ ListNum.num_lot }}
              </p>
            </div>
            <div v-if="ShowGroupList('3ตัวล่าง')">
              <p>สามตัวล่าง</p>
              <p
                v-for="(ListNum,IndexNum) in ShowGroupList('3ตัวล่าง')"
                :key="ListNum.num_lot"
              >
                {{ IndexNum+1 }}. {{ ListNum.num_lot }}
              </p>
            </div>
            <div v-if="ShowGroupList('3ตัวโต๊ด')">
              <p>สามตัวโต๊ด</p>
              <p
                v-for="(ListNum,IndexNum) in ShowGroupList('3ตัวโต๊ด')"
                :key="ListNum.num_lot"
              >
                {{ IndexNum+1 }}. {{ ListNum.num_lot }}
              </p>
            </div>
            <div v-if="ShowGroupList('2ตัวบน')">
              <p>สองตัวบน</p>
              <p
                v-for="(ListNum,IndexNum) in ShowGroupList('2ตัวบน')"
                :key="ListNum.num_lot"
              >
                {{ IndexNum+1 }}. {{ ListNum.num_lot }}
              </p>
            </div>
            <div v-if="ShowGroupList('2ตัวล่าง')">
              <p>สองตัวล่าง</p>
              <p
                v-for="(ListNum,IndexNum) in ShowGroupList('2ตัวล่าง')"
                :key="ListNum.num_lot"
              >
                {{ IndexNum+1 }}. {{ ListNum.num_lot }}
              </p>
            </div>
            <div v-if="ShowGroupList('2ตัวโต๊ด(บน)')">
              <p>สองตัวโต๊ด(บน)</p>
              <p
                v-for="(ListNum,IndexNum) in ShowGroupList('2ตัวโต๊ด(บน)')"
                :key="ListNum.num_lot"
              >
                {{ IndexNum+1 }}. {{ ListNum.num_lot }}
              </p>
            </div>
            <div v-if="ShowGroupList('วิ่งบน')">
              <p>วิ่งบน</p>
              <p
                v-for="(ListNum,IndexNum) in ShowGroupList('วิ่งบน')"
                :key="ListNum.num_lot"
              >
                {{ IndexNum+1 }}. {{ ListNum.num_lot }}
              </p>
            </div>
            <div v-if="ShowGroupList('วิ่งล่าง')">
              <p>วิ่งล่าง</p>
              <p
                v-for="(ListNum,IndexNum) in ShowGroupList('วิ่งล่าง')"
                :key="ListNum.num_lot"
              >
                {{ IndexNum+1 }}. {{ ListNum.num_lot }}
              </p>
            </div>

          </div>
          <b-sidebar
            id="sidebar-footer"
            aria-label="Sidebar with custom footer"
            shadow
            title="รายการแทง"
            backdrop
            bg-variant="white"
          >
            <b-card no-body>
              <!-- <h3 class="ml-1">

              </h3> -->
              <template>
                <validation-observer ref="BetItemsRules">
                  <b-table
                    responsive
                    head-variant="light"
                    :fields="fields"
                    :items="items"
                    class="mb-0 text-center"
                  >
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                          width: field.key === 'foo' ? '120px' : '180px',
                        }"
                      >
                    </template>
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>

                    <template #cell(price)="data">
                      <validation-provider
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(data.item.num_type).Min
                        },${CheckAmount(data.item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="data.item.price"
                          type="number"
                          class="form-css text-center"
                          placeholder=""
                          @change="
                            CheckAmount(
                              data.item.num_type,
                              data.item.price,
                              data.index,
                              data.item.num_lot,
                            )
                          "
                        />
                        <!-- @change="CheckAmount(data.item.num_type,data.item.price,data.index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              data.item.price,
                              CheckAmount(data.item.num_type).Min,
                              CheckAmount(data.item.num_type).Max,
                            ) === true
                            ? `ระบุจำนวนเงิน ${Commas(
                              CheckAmount(data.item.num_type).Min,
                            )}-${Commas(
                              CheckAmount(data.item.num_type).Max,
                            )} บาท`
                            : ''
                        }}</small>
                      </validation-provider>
                    </template>

                    <template #cell(win_rate)="data">
                      <p
                        :style="`color: ${
                          data.item.RateLimit ? 'red' : 'black'
                        }`"
                      >
                        {{ data.item.win_rate }}
                      </p>
                    </template>

                    <template #cell(delete)="data">
                      <!-- {{ data.index }} -->
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="items.splice(data.index, 1)"
                      >
                        <i class="fas fa-trash-alt" />
                      </b-button>
                    </template>
                  </b-table>
                </validation-observer>
              </template>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
            </b-card>
            <div class="fix-price footer footer-light footer-static">
              <b-row>
                <b-col
                  lg="4"
                  md="4"
                  cols="4"
                  class="cradit-total text-center mb-1"
                >
                  <div class="bg-cradit">
                    ยอดเครดิตคงเหลือ
                  </div>
                  {{
                    Commas(this.$store.state.appConfig.UserData.MainWallet)
                  }}
                  ฿
                </b-col>

                <b-col
                  lg="4"
                  md="4"
                  cols="4"
                  class="cradit text-center mb-1"
                >
                  <div class="bg-cradit">
                    ยอดแทงหวยทั้งหมด
                  </div>
                  {{ Commas(CalSumAmount()) }} ฿
                </b-col>

                <b-col
                  lg="4"
                  md="4"
                  cols="4"
                  class="cradit-total-after text-center mb-1"
                >
                  <div class="bg-cradit">
                    ยอดเครดิตคงเหลือหลังแทง
                  </div>
                  {{
                    Commas(
                      this.$store.state.appConfig.UserData.MainWallet -
                        CalSumAmount(),
                    )
                  }}
                  ฿
                </b-col>

                <b-col
                  lg="6"
                  md="6"
                  cols="4"
                  class="pl-1 pr-1"
                >
                  <b-button
                    block
                    variant="outline-warning"
                    @click=";(items = []), (Historyitems = [])"
                  >
                    <i class="fas fa-ban" /> ยกเลิกโพย
                  </b-button>
                </b-col>

                <b-col
                  lg="6"
                  md="6"
                  cols="12"
                  class="pl-1 pr-1"
                >
                  <b-button
                    :disabled="!CheckSubmit || items.length === 0"
                    block
                    variant="success"
                    @click="SummitBet"
                  >
                    <i class="fas fa-vote-yea" /> ส่งโพย
                  </b-button>
                </b-col>
              </b-row>
            </div>

          </b-sidebar>
        </div>
      </div>

      <div class="col-9">
        <!-- 3 ตัว -->
        <div
          v-if="ShowDigit == 3"
          class="row row-btn-tanghuay setting__number"
        >
          <div
            v-if="Title.LottoDetail.u3"
            class="col-6 col-sm-6 col-md-4 col-lg-3"
          >
            <b-button
              variant="outline-danger"
              class="btn-outline-red btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType3('3ตัวบน')"
            >
              <div class="bg-danger text-white">
                3 ตัวบน
              </div>
              <div>{{ RateAll.Three.Up.Rate }}</div>
            </b-button>
          </div>

          <div
            v-if="Title.LottoDetail.d3"
            class="col-6 col-sm-6 col-md-4 col-lg-3"
          >
            <b-button
              variant="outline-danger"
              class="btn-outline-red btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType3('3ตัวล่าง')"
            >
              <div class="bg-danger text-white">
                3 ตัวล่าง
              </div>
              <div>{{ RateAll.Three.Down.Rate }}</div>
            </b-button>
          </div>
          <div
            v-if="Title.LottoDetail.t3"
            class="col-6 col-sm-6 col-md-4 col-lg-3"
          >
            <b-button
              variant="outline-danger"
              class="btn-outline-red btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType3('3ตัวโต๊ด')"
            >
              <div class="bg-danger text-white">
                3 ตัวโต๊ด
              </div>
              <div>{{ RateAll.Three.Tot.Rate }}</div>
            </b-button>
          </div>
          <div class="col-6 col-sm-6 col-md-4 col-lg-3">
            <b-button
              variant="outline-danger"
              class="btn-outline-red btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType3('กลับตัวเลข')"
            >
              <div class="bg-danger text-white">
                3 ตัวกลับ
              </div>
              <div class="text-center">
                <i
                  aria-hidden="true"
                  class="fas fa-random"
                />
              </div>
            </b-button>
          </div>
        </div>

        <!-- 2 ตัว -->
        <div
          v-if="ShowDigit == 2"
          class="row row-btn-tanghuay setting__number"
        >
          <div
            v-if="Title.LottoDetail.u2"
            class="col-6 col-sm-6 col-md-4 col-lg-4"
          >
            <b-button
              variant="outline-info"
              class="btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType2('2ตัวบน')"
            >
              <div class="bg-blue text-white">
                2 ตัวบน
              </div>
              <div>{{ RateAll.Two.Up.Rate }}</div>
            </b-button>
          </div>

          <div
            v-if="Title.LottoDetail.d2"
            class="col-6 col-sm-6 col-md-4 col-lg-4"
          >
            <b-button
              variant="outline-info"
              class="btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType2('2ตัวล่าง')"
            >
              <div class="bg-blue text-white">
                2 ตัวล่าง
              </div>
              <div>{{ RateAll.Two.Down.Rate }}</div>
            </b-button>
          </div>
          <div
            v-if="Title.LottoDetail.t2"
            class="col-6 col-sm-6 col-md-4 col-lg-4"
          >
            <b-button
              variant="outline-info"
              class="btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType2('2ตัวโต๊ด(บน)')"
            >
              <div class="bg-blue text-white">
                2 ตัวโต๊ด(บน)
              </div>
              <div>{{ RateAll.Two.Tot.Rate }}</div>
            </b-button>
          </div>

          <div
            v-if="
              BetType.indexOf('2ตัวบน') >= 0 ||
                BetType.indexOf('2ตัวล่าง') >= 0
            "
            class="col-12 col-sm-12 col-md-12 col-lg-12 bg-option p-1 pb-0 mt-1 rounded box__two-option box__play setting__number"
          >
            <i
              aria-hidden="true"
              class="fas fa-bars"
            /> ตัวเลือกเพิ่มเติม
            <div
              class="d-flex flex-lg-row justify-content-around align-content-stretch flex-wrap"
            >
              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-danger btn-sm btn-block h-100 bet_two option2btn"
                    @click="SelectBetType2('19ประตู')"
                  >
                    19 ประตู
                  </button>
                </div>
              </div>
              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-primary btn-sm btn-block h-100 bet_two option2btn"
                    @click="SelectBetType2('รูดหน้า')"
                  >
                    รูดหน้า
                  </button>
                </div>
              </div>
              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-primary btn-sm btn-block h-100 bet_two option2btn"
                    @click="SelectBetType2('รูดหลัง')"
                  >
                    รูดหลัง
                  </button>
                </div>
              </div>

              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-info btn-sm btn-block h-100 bet_two"
                    @click="SelectBetType2('สองตัวต่ำ')"
                  >
                    สองตัวต่ำ
                  </button>
                </div>
              </div>

              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-info btn-sm btn-block h-100 bet_two"
                    @click="SelectBetType2('สองตัวสูง')"
                  >
                    สองตัวสูง
                  </button>
                </div>
              </div>

              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-success btn-sm btn-block h-100 bet_two"
                    @click="SelectBetType2('สองตัวคี่')"
                  >
                    สองตัวคี่
                  </button>
                </div>
              </div>

              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-success btn-sm btn-block h-100 bet_two"
                    @click="SelectBetType2('สองตัวคู่')"
                  >
                    สองตัวคู่
                  </button>
                </div>
              </div>

              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-success btn-sm btn-block h-100 bet_two"
                    @click="SelectBetType2('กลับตัวเลข')"
                  >
                    กลับตัวเลข
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 1 ตัว -->
        <div
          v-if="ShowDigit == 1"
          class="row row-btn-tanghuay setting__number"
        >
          <div
            v-if="Title.LottoDetail.u1"
            class="col-6 col-sm-6 col-md-4 col-lg-3"
          >
            <b-button
              variant="outline-success"
              class="btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType1('วิ่งบน')"
            >
              <div class="bg-success text-white">
                วิ่งบน
              </div>
              <div>{{ RateAll.One.Up.Rate }}</div>
            </b-button>
          </div>

          <div
            v-if="Title.LottoDetail.d1"
            class="col-6 col-sm-6 col-md-4 col-lg-3"
          >
            <b-button
              variant="outline-success"
              class="btn-block h-100 d-flex justify-content-between align-items-center"
              size="lg"
              @click="SelectBetType1('วิ่งล่าง')"
            >
              <div class="bg-success text-white">
                วิ่งล่าง
              </div>
              <div>{{ RateAll.One.Down.Rate }}</div>
            </b-button>
          </div>
        </div>

        <!-- แทงตัวเลข -->
        <div>
          <div class="key-pad box__keyboard">
            <div>
              <div
                v-if="BetType.length > 0"
                class="text-center"
              >
                <b-badge
                  v-for="item in BetType"
                  :key="`BetType${item}`"
                  :variant="
                    item === 'กลับตัวเลข' ||
                      item === 'รูดหน้า' ||
                      item === 'รูดหลัง'
                      ? 'dark'
                      : ShowDigit === 3
                        ? 'danger'
                        : ShowDigit === 2
                          ? 'info'
                          : 'success'
                  "
                  class="mr-1"
                >
                  {{ item }}
                </b-badge>
              </div>
              <hr style="margin-top: 0px">
            </div>
            <!-- <div
              v-if="
                BetType.indexOf('3ตัวบน') >= 0 ||
                  BetType.indexOf('3ตัวล่าง') >= 0 ||
                  BetType.indexOf('3ตัวโต๊ด') >= 0 ||
                  BetType.indexOf('2ตัวบน') >= 0 ||
                  BetType.indexOf('2ตัวล่าง') >= 0 ||
                  BetType.indexOf('2ตัวโต๊ด(บน)') >= 0 ||
                  BetType.indexOf('วิ่งบน') >= 0 ||
                  BetType.indexOf('วิ่งล่าง') >= 0
              "
            > -->
            <div>

              <div class="key-pad box__keyboard">
                <h6
                  v-if="!CheckBetType"
                  class="text-center"
                >
                  ระบุตัวเลข {{ CalMaxLength() }} ตัว
                </h6>
                <h6
                  v-else
                  class="text-center"
                >
                  กรุณาเลือกประเภท
                </h6>
                <div class="text-center mt-1">
                  <otp-input
                    v-if="BugOtp === true"
                    v-model="NumBet"
                    class="style-1"
                    :length="CalMaxLength()"
                    pattern="[^0-9]+"
                    :ignore-pattern="false"
                    :size="32"
                    :disabled="CheckBetType"
                    @valid="handleOnComplete()"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-4 mmb-1">
                  <button
                    data-id="1"
                    class="btn btn-outline-info btn-block"
                    :disabled="CheckBetType"
                    @click="Betting(1)"
                  >
                    1
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="2"
                    class="btn btn-outline-info btn-block"
                    :disabled="CheckBetType"
                    @click="Betting(2)"
                  >
                    2
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="3"
                    class="btn btn-outline-info btn-block"
                    :disabled="CheckBetType"
                    @click="Betting(3)"
                  >
                    3
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="4"
                    class="btn btn-outline-info btn-block"
                    :disabled="CheckBetType"
                    @click="Betting(4)"
                  >
                    4
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="5"
                    class="btn btn-outline-info btn-block"
                    :disabled="CheckBetType"
                    @click="Betting(5)"
                  >
                    5
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="6"
                    class="btn btn-outline-info btn-block"
                    :disabled="CheckBetType"
                    @click="Betting(6)"
                  >
                    6
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="7"
                    class="btn btn-outline-info btn-block"
                    :disabled="CheckBetType"
                    @click="Betting(7)"
                  >
                    7
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="8"
                    class="btn btn-outline-info btn-block"
                    :disabled="CheckBetType"
                    @click="Betting(8)"
                  >
                    8
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="9"
                    class="btn btn-outline-info btn-block"
                    :disabled="CheckBetType"
                    @click="Betting(9)"
                  >
                    9
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    class="btn btn-dark btn-block btn-reset"
                    @click="NumBet = ''"
                  >
                    ล้าง
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    class="btn btn-outline-info btn-block"
                    :disabled="CheckBetType"
                    @click="Betting(0)"
                  >
                    0
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="delete"
                    class="btn btn-danger btn-block"
                    @click="NumBet = NumBet.slice(0, -1)"
                  >
                    <i
                      aria-hidden="true"
                      class="fas fa-backspace"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>

        <!-- เงื่อนไขการแทง -->
        <div>
          <div>
            <h5><i class="fas fa-exclamation-circle" /> เงื่อนไขการแทง</h5>
          </div>
          <div v-if="ShowDigit === 3">
            <!--condition 3ตัวบน (จ่าย 850) -->
            <div
              v-if="BetType.indexOf('3ตัวบน') >= 0"
              class="mb-1"
            >
              <div class="condition-top">
                <span> 3ตัวบน (จ่าย {{ RateAll.Three.Up.Rate }})</span>
              </div>
              <div class="condition-body">
                <b-row>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงขั้นต่ำต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Three.Up.Min) }}</p>
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงสูงสุดต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Three.Up.Max) }}</p>
                  </b-col>
                </b-row>
              </div>
            </div>

            <!--condition 3ตัวโต๊ด (จ่าย 120) -->
            <div
              v-if="BetType.indexOf('3ตัวล่าง') >= 0"
              class="mb-1"
            >
              <div class="condition-top">
                <span>3ตัวล่าง (จ่าย {{ RateAll.Three.Down.Rate }})</span>
              </div>
              <div class="condition-body">
                <b-row>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงขั้นต่ำต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Three.Down.Min) }}</p>
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงสูงสุดต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Three.Down.Max) }}</p>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div
              v-if="BetType.indexOf('3ตัวโต๊ด') >= 0"
              class="mb-1"
            >
              <div class="condition-top">
                <span>3ตัวโต๊ด (จ่าย {{ RateAll.Three.Tot.Rate }})</span>
              </div>
              <div class="condition-body">
                <b-row>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงขั้นต่ำต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Three.Tot.Min) }}</p>
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงสูงสุดต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Three.Tot.Max) }}</p>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div v-if="ShowDigit === 2">
            <!--condition 2บน (จ่าย 90) -->
            <div
              v-if="BetType.indexOf('2ตัวบน') >= 0"
              class="mb-1"
            >
              <div
                class="condition-top"
                style="background-color: #f78400"
              >
                <span>2ตัวบน (จ่าย {{ RateAll.Two.Up.Rate }})</span>
              </div>
              <div class="condition-body">
                <b-row>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงขั้นต่ำต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Two.Up.Min) }}</p>
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงสูงสุดต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Two.Up.Max) }}</p>
                  </b-col>
                </b-row>
              </div>
            </div>

            <!--condition 2ล่าง (จ่าย 90) -->
            <div
              v-if="BetType.indexOf('2ตัวล่าง') >= 0"
              class="mb-1"
            >
              <div
                class="condition-top"
                style="background-color: #f78400"
              >
                <span>2ตัวล่าง (จ่าย {{ RateAll.Two.Down.Rate }})</span>
              </div>
              <div class="condition-body">
                <b-row>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงขั้นต่ำต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Two.Down.Min) }}</p>
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงสูงสุดต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Two.Down.Max) }}</p>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div
              v-if="BetType.indexOf('2ตัวโต๊ด(บน)') >= 0"
              class="mb-1"
            >
              <div
                class="condition-top"
                style="background-color: #f78400"
              >
                <span>2ตัวโต๊ด(บน) (จ่าย {{ RateAll.Two.Tot.Rate }})</span>
              </div>
              <div class="condition-body">
                <b-row>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงขั้นต่ำต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Two.Tot.Min) }}</p>
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงสูงสุดต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.Two.Tot.Max) }}</p>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div v-if="ShowDigit === 1">
            <!--condition วิ่งบน (จ่าย 3.2) -->
            <div
              v-if="BetType.indexOf('วิ่งบน') >= 0"
              class="mb-1"
            >
              <div
                class="condition-top"
                style="background-color: #2e9d00"
              >
                <span>วิ่งบน (จ่าย {{ RateAll.One.Up.Rate }})</span>
              </div>
              <div class="condition-body">
                <b-row>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงขั้นต่ำต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.One.Up.Min) }}</p>
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงสูงสุดต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.One.Up.Max) }}</p>
                  </b-col>
                </b-row>
              </div>
            </div>

            <!--condition วิ่งล่าง (จ่าย 4.2) -->
            <div
              v-if="BetType.indexOf('วิ่งล่าง') >= 0"
              class="mb-1"
            >
              <div
                class="condition-top"
                style="background-color: #2e9d00"
              >
                <span>วิ่งล่าง (จ่าย {{ RateAll.One.Down.Rate }})</span>
              </div>
              <div class="condition-body">
                <b-row>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงขั้นต่ำต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.One.Down.Min) }}</p>
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-center"
                  >
                    <small>แทงสูงสุดต่อครั้ง/เลข</small>
                    <p>{{ Commas(RateAll.One.Down.Max) }}</p>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </b-overlay> -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, between, digits } from '@validations'
import {
  BButton,
  BRow,
  BCol,
  // BModal,
  BTable,
  BFormInput,
  BCard,
  BBadge,
  // BAvatar,
  BSidebar,
  VBToggle,
  // BOverlay,
  // BIconController,
} from 'bootstrap-vue'

// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import HomeBanner from './HomeBanner.vue'
// import HomeSponsor from './HomeSponsor.vue'
import 'swiper/css/swiper.css'
import momenttz from 'moment-timezone'
import OtpInput from '@8bu/vue-otp-input'
// import SidebarContent from './SidebarContent.vue'
// import SidebarContentFooter from './SidebarContentFooter.vue'

export default {
  components: {
    BButton,
    // HomeBanner,
    // HomeSponsor,
    BRow,
    BCol,
    OtpInput,
    // BModal,
    BTable,
    BFormInput,
    BCard,
    BBadge,
    // BAvatar,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    // SidebarContentFooter,
    // BOverlay,
    // BIconController,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      required,
      between,
      digits,
      CheckSubmit: true,
      BugOtp: true,
      show: false,
      ShowLimit: false,
      ShowClose: false,
      fieldsModal: [
        { key: 'type', label: 'ประเภท' },
        { key: 'NumBet', label: 'เลขที่แทง' },
      ],
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      SumAmount: 0,
      AmountBet: 1,
      NumBet: '',
      otp: '',
      Status: 'Diposit',
      ShowDigit: 3,
      CheckBetType: true,
      maxlength: 3,
      // BetType: ['3ตัวบน', 'กลับตัวเลข'],
      BetType: [],
      Interval: null,
      IntervalRate: null,
      fields: [
        { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'delete', label: 'ลบ' },
      ],
      items: [],
      Historyitems: [],
      RateAll: {
        Three: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        Two: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        One: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
      },
      MinAmount: 0,
      MaxAmount: 0,
      Title: {
        RoundID: '',
        LottoName: '',
        LottoHead: null,
        LottoSubHead: null,
        LottoDetail: [],
        CloseBet: '',
        CloseDate: '',
        CloseDateName: '',
        ShowCloseDate: '',
        LimitU3: [],
        LimitD3: [],
        LimitT3: [],
        LimitU2: [],
        LimitD2: [],
        LimitT2: [],
        LimitU1: [],
        LimitD1: [],
        CloseBetU3: [],
        CloseBetD3: [],
        CloseBetT3: [],
        CloseBetU2: [],
        CloseBetD2: [],
        CloseBetT2: [],
        CloseBetU1: [],
        CloseBetD1: [],
      },
      CloseBet: {
        CloseU3: [],
        CloseD3: [],
        CloseT3: [],
        CloseU2: [],
        CloseD2: [],
        CloseT2: [],
        CloseU1: [],
        CloseD1: [],
      },
      CloseBetModal: [],
    }
  },

  beforeDestroy() {
    clearInterval(this.Interval)
    clearInterval(this.IntervalRate)
  },
  async mounted() {
    const Type = JSON.parse(atob(this.$route.params.Type))
    this.Title.LottoHead = Type.LottoHead
    this.Title.LottoSubHead = Type.LottoSubHead
    this.show = true
    this.LottoList = await this.GetSublist()
    await this.ShowTitle()
    await this.GetMaxMin()
    await this.GetRate(this.Title.RoundID)
    this.show = false
    this.Interval = setInterval(() => {
      if (this.Title.CloseDate) {
        this.Title.ShowCloseDate = this.CalDiffTime(this.Title.CloseDate)
      }
    }, 1000)
    this.IntervalRate = setInterval(() => {
      this.GetRate(this.Title.RoundID)
    }, 60000)
    this.Interval = setInterval(() => {
      this.ShowTitle()
      this.GetMaxMin()
    }, 300000)
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get('https://api.ma5lotto.com/api/LottoList')
        // eslint-disable-next-line
        for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
            for (const c in Res[x].Subhead) {
              SubList.push(
                { text: Res[x].Subhead[c].name, value: Res[x].Subhead[c].SubHeadID, Data: Res[x].Subhead[c] },
              )
            }
          }
          List.push(
            {
              text: Res[x].name,
              value: Res[x].HeadID,
              SubList,
              Data: Res[x],
            },
          )
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    groupBy(list, keyGetter) {
      const map = new Map()
      list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
          map.set(key, [item])
        } else {
          collection.push(item)
        }
      })
      return map
    },
    ShowGroupList(Key) {
      if (this.items.length > 0) {
        const grouped = this.groupBy(this.items, group => group.num_type)
        return grouped.get(Key)
      }
      return null
    },
    handleOnComplete() {
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 100)
      }
    },
    Betting(val) {
      if (this.NumBet.length < this.CalMaxLength()) {
        this.NumBet += val
      }
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 200)
        // console.log(this.items)
      }
    },
    showAlert(CloseBet) {
      this.CloseBetModal = CloseBet
      this.$refs['modal-alert'].show()
    },
    async SummitBet() {
      const CheckPrice = this.items.some(el => el.price === '')
      this.$refs.BetItemsRules.validate().then(async success => {
        if (success && CheckPrice === false) {
          this.CheckSubmit = false
          if (
            this.SumAmount > this.$store.state.appConfig.UserData.MainWallet
          ) {
            this.SwalMes(
              'error',
              'จำนวนเงินไม่ถูกต้อง !',
              'ยอดเงินของคุณไม่เพียงพอกรุณาลองใหม่อีกครั้งค่ะ',
            )
            this.items.splice(this.items.length - 1, 1)
          } else {
            this.show = true
            const Obj = {
              SumAmount: this.SumAmount,
              OBJ: this.items,
            }
            try {
              // const { data: response } = await this.$http.post(
              //   'https://api.ma5lotto.com/api/bet/store',
              //   Obj,
              // )
              const { data: response } = await this.$http.post(
                'https://api.ma5lotto.com/api/bet/store',
                Obj,
              )
              if (response) {
                this.ShowWallet()
                this.ShowTitle()
                this.GetMaxMin()
                this.GetRate(this.Title.RoundID)
                this.show = false
                this.items = []
                this.CheckSubmit = true
                if (response.success === true) {
                  this.SwalMes(
                    'success',
                    'ส่งโพยเรียบร้อย',
                    `ยอดแทงหวยทั้งหมด ${this.Commas(
                      Obj.SumAmount,
                    )} บาท ยอดคงเหลือ ${this.Commas(
                      response.WalletData.balance,
                    )} บาท`,
                  )
                } else {
                  this.showAlert(response.Mes)
                }
              }
            } catch (err) {
              this.SwalMes('error', 'ส่งโพยไม่สำเร็จ', err.response.data.Mes)
              // console.log(err)
              this.CheckSubmit = true
            }
          }
        }
      })
    },
    async ShowWallet() {
      try {
        const { data: response } = await this.$http.get(
          'https://api.ma5lotto.com/api/wallet/show',
        )
        if (response && response.success === true) {
          const Main = response.WalletData.MainWallet
          this.$store.state.appConfig.UserData.MainWallet = Main.balance
        } else {
          this.$store.state.appConfig.UserData.MainWallet = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
    async ShowTitle() {
      const IndexHead = this.LottoList.findIndex(
        ({ value }) => value === this.Title.LottoHead,
      )
      if (this.Title.LottoSubHead) {
        const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === this.Title.LottoSubHead)
        this.Title.LottoName = this.LottoList[IndexHead].SubList[IndexSub].text
        this.Title.LottoDetail = this.LottoList[IndexHead].SubList[IndexSub].Data
      } else {
        this.Title.LottoName = this.LottoList[IndexHead].text
        this.Title.LottoDetail = this.LottoList[IndexHead].Data
      }
      if (this.Title.LottoDetail.u3 || this.Title.LottoDetail.d3 || this.Title.LottoDetail.t3) {
        this.ShowDigit = 3
      } else if (this.Title.LottoDetail.u2 || this.Title.LottoDetail.d2 || this.Title.LottoDetail.t2) {
        this.ShowDigit = 2
      } else if (this.Title.LottoDetail.u1 || this.Title.LottoDetail.d1) {
        this.ShowDigit = 1
      }
      const params = {
        LottoHead: this.Title.LottoHead,
        LottoSubHead: this.Title.LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.ma5lotto.com/api/round/ShowByHeadAndSub',
          { params },
        )
        if (ResData.success) {
          // eslint-disable-next-line no-underscore-dangle
          this.Title.RoundID = ResData.mes._id
          this.Title.CloseBet = ResData.mes.StopBetTime
          this.Title.CloseDate = ResData.mes.CloseDate
          this.Title.CloseDateName = ResData.mes.CloseDateName
          this.Title.LimitU3 = ResData.mes.LimitU3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD3 = ResData.mes.LimitD3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT3 = ResData.mes.LimitT3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU2 = ResData.mes.LimitU2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD2 = ResData.mes.LimitD2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT2 = ResData.mes.LimitT2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU1 = ResData.mes.LimitU1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD1 = ResData.mes.LimitD1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.CloseBetU3 = ResData.mes.CloseBetU3.map(x => x.NumLot)
          this.Title.CloseBetD3 = ResData.mes.CloseBetD3.map(x => x.NumLot)
          this.Title.CloseBetT3 = ResData.mes.CloseBetT3.map(x => x.NumSort)
          this.Title.CloseBetU2 = ResData.mes.CloseBetU2.map(x => x.NumLot)
          this.Title.CloseBetD2 = ResData.mes.CloseBetD2.map(x => x.NumLot)
          this.Title.CloseBetT2 = ResData.mes.CloseBetT2.map(x => x.NumSort)
          this.Title.CloseBetU1 = ResData.mes.CloseBetU1.map(x => x.NumLot)
          this.Title.CloseBetD1 = ResData.mes.CloseBetD1.map(x => x.NumLot)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetMaxMin() {
      const params = {
        LottoHead: this.Title.LottoHead,
        LottoSubHead: this.Title.LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.ma5lotto.com/api/rate/ShowByHeadAndSub',
          // 'https://api.ma5lotto.com/api/round/ShowByHeadAndSub',
          { params },
        )
        if (ResData.success) {
          this.RateAll.Three.Up.Min = ResData.mes.MinU3
          this.RateAll.Three.Up.Max = ResData.mes.MaxU3
          this.RateAll.Three.Down.Min = ResData.mes.MinD3
          this.RateAll.Three.Down.Max = ResData.mes.MaxD3
          this.RateAll.Three.Tot.Min = ResData.mes.MinT3
          this.RateAll.Three.Tot.Max = ResData.mes.MaxT3
          this.RateAll.Two.Up.Min = ResData.mes.MinU2
          this.RateAll.Two.Up.Max = ResData.mes.MaxU2
          this.RateAll.Two.Down.Min = ResData.mes.MinD2
          this.RateAll.Two.Down.Max = ResData.mes.MaxD2
          this.RateAll.Two.Tot.Min = ResData.mes.MinT2
          this.RateAll.Two.Tot.Max = ResData.mes.MaxT2
          this.RateAll.One.Up.Min = ResData.mes.MinU1
          this.RateAll.One.Up.Max = ResData.mes.MaxU1
          this.RateAll.One.Down.Min = ResData.mes.MinD1
          this.RateAll.One.Down.Max = ResData.mes.MaxD1
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetRate(RoundID) {
      const params = {
        RoundID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.ma5lotto.com/api/ratebyround/showbetmore',
          { params },
        )
        if (ResData.success) {
          const Rate = ResData.mes.Data
          const ListBet = ResData.mes.LimitData
          this.RateAll.Three.Up.Rate = Rate.U3
          this.RateAll.Three.Up.BetLimit = Rate.BetLimitU3
          this.RateAll.Three.Up.BetList = ListBet.U3
          this.RateAll.Three.Down.Rate = Rate.D3
          this.RateAll.Three.Down.BetLimit = Rate.BetLimitD3
          this.RateAll.Three.Down.BetList = ListBet.D3
          this.RateAll.Three.Tot.Rate = Rate.T3
          this.RateAll.Three.Tot.BetLimit = Rate.BetLimitT3
          this.RateAll.Three.Tot.BetList = ListBet.T3
          this.RateAll.Two.Up.Rate = Rate.U2
          this.RateAll.Two.Up.BetLimit = Rate.BetLimitU2
          this.RateAll.Two.Up.BetList = ListBet.U2
          this.RateAll.Two.Down.Rate = Rate.D2
          this.RateAll.Two.Down.BetLimit = Rate.BetLimitD2
          this.RateAll.Two.Down.BetList = ListBet.D2
          this.RateAll.Two.Tot.Rate = Rate.T2
          this.RateAll.Two.Tot.BetLimit = Rate.BetLimitT2
          this.RateAll.Two.Tot.BetList = ListBet.T2
          this.RateAll.One.Up.Rate = Rate.U1
          this.RateAll.One.Up.BetLimit = Rate.BetLimitU1
          this.RateAll.One.Up.BetList = ListBet.U1
          this.RateAll.One.Down.Rate = Rate.D1
          this.RateAll.One.Down.BetLimit = Rate.BetLimitD1
          this.RateAll.One.Down.BetList = ListBet.D1
          // this.CloseBet.CloseU3.push(
          //   ...this.CalCloseBet(ListBet.U3, Rate.BetLimitU3),
          // )
          this.CloseBet.CloseU3 = this.CalCloseBet(
            ListBet.U3,
            this.Title.CloseBetU3,
            Rate.BetLimitU3,
          )
          this.CloseBet.CloseD3 = this.CalCloseBet(
            ListBet.D3,
            this.Title.CloseBetD3,
            Rate.BetLimitD3,
          )
          this.CloseBet.CloseT3 = this.CalCloseBet(
            ListBet.T3,
            this.Title.CloseBetT3,
            Rate.BetLimitT3,
          )
          this.CloseBet.CloseU2 = this.CalCloseBet(
            ListBet.U2,
            this.Title.CloseBetU2,
            Rate.BetLimitU2,
          )
          this.CloseBet.CloseD2 = this.CalCloseBet(
            ListBet.D2,
            this.Title.CloseBetD2,
            Rate.BetLimitD2,
          )
          this.CloseBet.CloseT2 = this.CalCloseBet(
            ListBet.T2,
            this.Title.CloseBetT2,
            Rate.BetLimitT2,
          )
          this.CloseBet.CloseU1 = this.CalCloseBet(
            ListBet.U1,
            this.Title.CloseBetU1,
            Rate.BetLimitU1,
          )
          this.CloseBet.CloseD1 = this.CalCloseBet(
            ListBet.D1,
            this.Title.CloseBetD1,
            Rate.BetLimitD1,
          )

          this.Title.LimitU3 = this.CalLimit(
            this.Title.LimitU3,
            this.CloseBet.CloseU3,
          )
          this.Title.LimitD3 = this.CalLimit(
            this.Title.LimitD3,
            this.CloseBet.CloseD3,
          )
          this.Title.LimitT3 = this.CalLimit(
            this.Title.LimitT3,
            this.CloseBet.CloseT3,
          )
          this.Title.LimitU2 = this.CalLimit(
            this.Title.LimitU2,
            this.CloseBet.CloseU2,
          )
          this.Title.LimitD2 = this.CalLimit(
            this.Title.LimitD2,
            this.CloseBet.CloseD2,
          )
          this.Title.LimitT2 = this.CalLimit(
            this.Title.LimitT2,
            this.CloseBet.CloseT2,
          )
          this.Title.LimitU1 = this.CalLimit(
            this.Title.LimitU1,
            this.CloseBet.CloseU1,
          )
          this.Title.LimitD1 = this.CalLimit(
            this.Title.LimitD1,
            this.CloseBet.CloseD1,
          )
        }
      } catch (e) {
        console.log(e)
      }
    },
    CompleteNumBet() {
      if (this.ShowDigit === 3) {
        this.PushDigit3()
      } else if (this.ShowDigit === 2) {
        this.PushDigit2()
      } else if (this.ShowDigit === 1) {
        this.PushDigit1()
      }
    },
    async PushDigit3() {
      const Swap = this.BetType.indexOf('กลับตัวเลข')
      const UP3 = this.BetType.indexOf('3ตัวบน')
      const DOWN3 = this.BetType.indexOf('3ตัวล่าง')
      const TOT = this.BetType.indexOf('3ตัวโต๊ด')
      // eslint-disable-next-line no-nested-ternary
      if (Swap >= 0) {
        if (UP3 >= 0) {
          const Switch3Arr = this.SwitchNum3(this.NumBet)
          await Switch3Arr.forEach(ele => {
            this.PushItems(ele, '3ตัวบน', 3, 'UP', this.AmountBet)
          })
        }
        if (DOWN3 >= 0) {
          const Switch3Arr = this.SwitchNum3(this.NumBet)
          await Switch3Arr.forEach(ele => {
            this.PushItems(ele, '3ตัวล่าง', 3, 'DOWN', this.AmountBet)
          })
        }
      } else if (TOT >= 0 || UP3 >= 0 || DOWN3 >= 0) {
        if (TOT >= 0) {
          await this.PushItems(
            this.NumBet,
            '3ตัวโต๊ด',
            3,
            'TOT',
            this.AmountBet,
          )
        }
        if (UP3 >= 0) {
          await this.PushItems(this.NumBet, '3ตัวบน', 3, 'UP', this.AmountBet)
        }
        if (DOWN3 >= 0) {
          await this.PushItems(
            this.NumBet,
            '3ตัวล่าง',
            3,
            'DOWN',
            this.AmountBet,
          )
        }
      }
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushDigit2() {
      const Up = this.BetType.indexOf('2ตัวบน') >= 0
      const Down = this.BetType.indexOf('2ตัวล่าง') >= 0
      const Tot = this.BetType.indexOf('2ตัวโต๊ด(บน)') >= 0
      if (this.BetType.indexOf('19ประตู') >= 0) {
        const Res = this.Swipe19(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('รูดหน้า') >= 0) {
        const Res = this.SwipeFront(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('รูดหลัง') >= 0) {
        const Res = this.SwipeBack(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else if (this.BetType.indexOf('กลับตัวเลข') >= 0) {
        const Res = this.SwitchNum2(this.NumBet)
        if (Up) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.AmountBet)
          })
        }
        if (Down) {
          await Res.forEach(ele => {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
          })
        }
      } else {
        if (Up) {
          await this.PushItems(this.NumBet, '2ตัวบน', 2, 'UP', this.AmountBet)
        }
        if (Down) {
          await this.PushItems(this.NumBet, '2ตัวล่าง', 2, 'DOWN', this.AmountBet)
        }
        if (Tot) {
          await this.PushItems(this.NumBet, '2ตัวโต๊ด(บน)', 2, 'TOT', this.AmountBet)
        }
      }
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushDigit1() {
      const NumType = this.BetType.indexOf('วิ่งบน') >= 0 ? 'วิ่งบน' : 'วิ่งล่าง'
      const NumTypePrefix = this.BetType.indexOf('วิ่งบน') >= 0 ? 'UP' : 'DOWN'

      await this.PushItems(
        this.NumBet,
        NumType,
        1,
        NumTypePrefix,
        this.AmountBet,
      )
      this.ClearAfterBet()
      this.PushHistory(this.items)
    },
    async PushItems(NumBet, NumType, BetDigit, BetType, Price) {
      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => {
        const { Rate, RateLimit } = this.CalRate(NumBet, BetDigit, BetType)
        let NumSort = null
        let CheckDup = null
        if (BetType === 'TOT') {
          const NumArr = NumBet.split('')
          NumArr.sort()
          NumSort = NumArr.join('')
          CheckDup = this.items.findIndex(
            obj => obj.NumSort === NumSort
            && obj.bet_digit === BetDigit
            && obj.bet_type === BetType,
          )
        } else {
          CheckDup = this.items.findIndex(
            obj => obj.num_lot === NumBet
            && obj.bet_digit === BetDigit
            && obj.bet_type === BetType,
          )
        }

        if (CheckDup >= 0) {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price) + Number(this.items[CheckDup].price),
          )
          if (CheckLimit.status === true) {
            this.items[CheckDup].price = Number(Price) + Number(this.items[CheckDup].price)
            resolve()
          } else if (CheckLimit.amount <= 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
            reject()
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} บาท`,
            )
            reject()
          }
        } else {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price),
          )
          if (CheckLimit.status === true) {
            if (RateLimit) {
              this.SwalMes('info', 'หมายเลขจ่ายตาม %', `${NumType} หมายเลข ${NumBet} จ่าย ${Rate} บาท`)
            }
            const ObjItems = {
              num_lot: NumBet,
              num_type: NumType,
              bet_digit: BetDigit, // 1, 2 ,3
              bet_type: BetType, // TOT , UP , DOWN
              price: Price,
              round_id: this.Title.RoundID,
              LottoHead: this.Title.LottoHead,
              LottoSubHead: this.Title.LottoSubHead
                ? this.Title.LottoSubHead
                : null,
              win_rate: Rate,
              NumSort,
              RateLimit,
            }
            this.items.push(ObjItems)
            resolve()
          } else if (CheckLimit.amount === 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
            reject()
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} บาท`,
            )
            reject()
          }
        }
      })
    },
    async PushHistory(Val) {
      const St = JSON.stringify(Val)
      this.Historyitems.push(JSON.parse(St))
    },
    CalCloseBet(Arr, CloseList, BetLimit) {
      let arrArr = Arr.filter(ele => ele.totalAmount >= BetLimit)
      // eslint-disable-next-line no-underscore-dangle
      arrArr = arrArr.map(x => x._id)
      if (CloseList.length > 0) {
        arrArr.push(...CloseList)
      }
      return arrArr.sort()
    },
    CalRate(NumBet, BetDigit, BetType) {
      let Rate = 0
      let IndexLimit = null
      let RateLimit = false
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Down.Rate
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Down.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Tot.Rate
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Tot.Rate
          }
        }
      }
      if (IndexLimit >= 0) {
        RateLimit = true
      }
      return { Rate, RateLimit }
    },
    CalLimit(Limit, CloseBet) {
      return Limit.filter(val => !CloseBet.includes(val.NumLot))
    },
    SelectDigit(Val) {
      this.NumBet = ''
      this.AmountBet = 1
      this.BetType = []
      this.CheckBetType = true
      this.maxlength = Val
      this.ShowDigit = Val
      this.BugOtp = false
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.BugOtp = true), 10)
    },
    ClearAfterBet() {
      this.NumBet = ''
      this.AmountBet = 1
      this.BugOtp = false
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.BugOtp = true), 10)
    },
    SelectBetType3(Val) {
      this.SelectUnDup(this.BetType, Val)
      if (Val === 'กลับตัวเลข') {
        this.CheckRemove(this.BetType, '3ตัวโต๊ด')
      }
      if (Val === '3ตัวโต๊ด') {
        this.CheckRemove(this.BetType, 'กลับตัวเลข')
      }
      this.CheckBetDisable(this.BetType, '3ตัวบน', '3ตัวโต๊ด', '3ตัวล่าง')
    },
    async SelectBetType2(Val) {
      // eslint-disable-next-line no-nested-ternary
      const NumType = this.BetType.indexOf('2ตัวบน') >= 0
        ? '2ตัวบน'
        : this.BetType.indexOf('2ตัวล่าง') >= 0
          ? '2ตัวล่าง'
          : '2ตัวโต๊ด(บน)'
      // eslint-disable-next-line no-nested-ternary
      const NumTypePrefix = this.BetType.indexOf('2ตัวบน') >= 0
        ? 'UP'
        : this.BetType.indexOf('2ตัวล่าง') >= 0
          ? 'DOWN'
          : 'TOT'
      if (Val === 'สองตัวคี่') {
        const Res = this.SwipeOdd()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวคู่') {
        const Res = this.SwipeEven()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวสูง') {
        const Res = this.SwipeHigh()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else if (Val === 'สองตัวต่ำ') {
        const Res = this.SwipeLow()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
        this.PushHistory(this.items)
      } else {
        this.SelectUnDup(this.BetType, Val)
        if (Val === '2ตัวบน') {
          this.maxlength = 2
          // this.CheckRemove(this.BetType, '2ตัวล่าง')
          this.CheckRemove(this.BetType, '2ตัวโต๊ด(บน)')
        } else if (Val === '2ตัวล่าง') {
          this.maxlength = 2
          // this.CheckRemove(this.BetType, '2ตัวบน')
          // this.CheckRemove(this.BetType, '2ตัวโต๊ด(บน)')
        } else if (Val === '2ตัวโต๊ด(บน)') {
          this.maxlength = 2
          this.CheckRemove(this.BetType, '2ตัวบน')
          this.CheckRemove(this.BetType, '2ตัวล่าง')
        } else if (Val === '19ประตู') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'รูดหน้า') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'รูดหลัง') {
          this.maxlength = 1
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'กลับตัวเลข')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        } else if (Val === 'กลับตัวเลข') {
          this.CheckRemove(this.BetType, '19ประตู')
          this.CheckRemove(this.BetType, 'รูดหน้า')
          this.CheckRemove(this.BetType, 'รูดหลัง')
          this.CheckRemove(this.BetType, 'สองตัวคี่')
          this.CheckRemove(this.BetType, 'สองตัวคู่')
          this.CheckRemove(this.BetType, 'สองตัวสูง')
          this.CheckRemove(this.BetType, 'สองตัวต่ำ')
        }
        this.CheckBetDisable(this.BetType, '2ตัวบน', '2ตัวล่าง', '2ตัวโต๊ด(บน)')
      }
    },
    SelectBetType1(Val) {
      this.SelectUnDup(this.BetType, Val)
      this.CheckBetDisable(this.BetType, 'วิ่งบน', 'วิ่งล่าง')
    },
    CheckRemove(Arr, Val) {
      const Index = Arr.indexOf(Val)
      if (Index >= 0) {
        Arr.splice(Index, 1)
      }
    },
    SelectUnDup(Arr, Val) {
      const Index = Arr.indexOf(Val)
      if (Index < 0) {
        Arr.push(Val)
        const { Min, Max } = this.CheckAmount(Val)
        if (Max !== 0) {
          this.MinAmount = Min
          this.MaxAmount = Max
        }
      } else {
        Arr.splice(Index, 1)
      }
    },
    CheckBetDisable(Arr, Val1, Val2, Val3 = null) {
      const Index1 = Arr.indexOf(Val1)
      const Index2 = Arr.indexOf(Val2)
      const Index3 = Arr.indexOf(Val3)
      if (Index1 >= 0 || Index2 >= 0 || Index3 >= 0) {
        this.CheckBetType = false
      } else {
        this.CheckBetType = true
      }
    },
    TestPrice(Price, Min, Max) {
      if (Price < Min || Price > Max) {
        return true
      }
      return false
    },
    CheckAmount(Val, Price, Index, NumBet = null) {
      const MinMax = { Min: 0, Max: 0 }
      let BetDigit = 3
      let BetType = 'UP'
      if (Val === '3ตัวบน') {
        MinMax.Min = this.RateAll.Three.Up.Min
        MinMax.Max = this.RateAll.Three.Up.Max
      } else if (Val === '3ตัวล่าง') {
        MinMax.Min = this.RateAll.Three.Down.Min
        MinMax.Max = this.RateAll.Three.Down.Max
        BetType = 'DOWN'
      } else if (Val === '3ตัวโต๊ด') {
        MinMax.Min = this.RateAll.Three.Tot.Min
        MinMax.Max = this.RateAll.Three.Tot.Max
        BetType = 'TOT'
      } else if (Val === '2ตัวบน') {
        MinMax.Min = this.RateAll.Two.Up.Min
        MinMax.Max = this.RateAll.Two.Up.Max
        BetDigit = 2
        BetType = 'UP'
      } else if (Val === '2ตัวล่าง') {
        MinMax.Min = this.RateAll.Two.Down.Min
        MinMax.Max = this.RateAll.Two.Down.Max
        BetDigit = 2
        BetType = 'DOWN'
      } else if (Val === '2ตัวโต๊ด(บน)') {
        MinMax.Min = this.RateAll.Two.Tot.Min
        MinMax.Max = this.RateAll.Two.Tot.Max
        BetDigit = 2
        BetType = 'TOT'
      } else if (Val === 'วิ่งบน') {
        MinMax.Min = this.RateAll.One.Up.Min
        MinMax.Max = this.RateAll.One.Up.Max
        BetDigit = 1
        BetType = 'UP'
      } else if (Val === 'วิ่งล่าง') {
        MinMax.Min = this.RateAll.One.Down.Min
        MinMax.Max = this.RateAll.One.Down.Max
        BetDigit = 1
        BetType = 'DOWN'
      }
      if (Price) {
        if (Price < MinMax.Min) {
          this.items[Index].price = MinMax.Min
        } else if (Price > MinMax.Max) {
          this.items[Index].price = MinMax.Max
        }
        const CheckLimit = this.CheckBetLimit(
          NumBet,
          BetDigit,
          BetType,
          Number(Price),
        )
        if (CheckLimit.status === false) {
          this.items[Index].price = CheckLimit.amount > 0 ? CheckLimit.amount : 0
        }
      }
      return MinMax
    },
    CheckBetLimit(NumBet, BetDigit, BetType, Price) {
      let TotalAmount = Price
      let SumAmount = 0
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT2.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT3.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      }
      return { status: true }
    },
    // 3Digit
    SwitchNum3(textnum) {
      const ReArr = []
      const num1 = textnum.substr(0, 1)
      const num2 = textnum.substr(1, 1)
      const num3 = textnum.substr(2, 1)
      if (num1 === num2 || num1 === num3 || num2 === num3) {
        if (num1 === num2) {
          ReArr.push(num1 + num1 + num3, num1 + num3 + num1, num3 + num1 + num1)
        } else if (num1 === num3) {
          ReArr.push(num1 + num2 + num1, num1 + num1 + num2, num2 + num1 + num1)
        } else {
          ReArr.push(num1 + num2 + num2, num2 + num2 + num1, num2 + num1 + num2)
        }
      } else {
        ReArr.push(
          num1 + num2 + num3,
          num1 + num3 + num2,
          num2 + num1 + num3,
          num2 + num3 + num1,
          num3 + num1 + num2,
          num3 + num2 + num1,
        )
      }
      return ReArr
    },
    // 2Digit
    SwitchNum2(textnum) {
      const ReArr = []
      const Val = String(textnum)
      const num1 = Val.substr(0, 1)
      const num2 = Val.substr(1, 1)
      if (num1 === num2) {
        ReArr.push(num1 + num2)
      } else {
        ReArr.push(num1 + num2, num2 + num1)
      }
      return ReArr
    },
    SwipeFront(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = String(Val) + i
        ReArr.push(R1)
      }
      return ReArr
    },
    SwipeBack(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      return ReArr
    },
    Swipe19(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        if (i === Number(Val)) {
          // eslint-disable-next-line no-plusplus
          i++
        }
        if (i !== 10) {
          const R2 = String(Val) + i
          ReArr.push(R2)
        }
      }
      return ReArr
    },
    SwipeLow() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 50; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeHigh() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 50; i < 100; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeOdd() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    SwipeEven() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2 !== 1) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    Undo() {
      if (this.Historyitems.length > 1) {
        this.Historyitems.pop()
        this.items = this.Historyitems[this.Historyitems.length - 1]
      } else {
        this.items = []
        this.Historyitems = []
      }
    },
    CalMaxLength() {
      const CheckLengthType = this.BetType.findIndex(
        obj => obj === '19ประตู' || obj === 'รูดหน้า' || obj === 'รูดหลัง',
      )
      if (CheckLengthType >= 0) {
        this.maxlength = 1
      } else {
        this.maxlength = this.ShowDigit
      }
      return this.maxlength
    },
    CalSumAmount() {
      const SumAmount = this.items.reduce(
        (acc, ele) => acc + Number(ele.price),
        0,
      )
      this.SumAmount = SumAmount
      if (SumAmount > this.$store.state.appConfig.UserData.MainWallet) {
        if (this.CheckSubmit) {
          this.SwalMes(
            'error',
            'จำนวนเงินไม่ถูกต้อง !',
            'ยอดเงินของคุณไม่เพียงพอกรุณาลองใหม่อีกครั้งค่ะ',
          )
          this.CheckSubmit = !this.CheckSubmit
        }

        // this.items.splice(this.items.length - 1, 1)
      } else {
        this.CheckSubmit = true
      }
      return SumAmount
    },
    // eslint-disable-next-line consistent-return
    CalDiffTime(Val) {
      const DateRound = momenttz(Val).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Seconds = DateRound.diff(DateNow, 'seconds')

      const Day = Math.floor(Seconds / 86400)
      const Hours = Math.floor((Seconds % 86400) / 3600)
      const Minutes = Math.floor((Seconds % 3600) / 60)
      const numseconds = Math.floor((Seconds % 3600) % 60)

      if (Day >= 0) {
        return `${Day} วัน ${Hours < 10 ? `0${Hours}` : Hours} : ${
          Minutes < 10 ? `0${Minutes}` : Minutes
        } : ${numseconds < 10 ? `0${numseconds}` : numseconds}`
      }
      clearInterval(this.Interval)
      this.$router.push({ name: 'lottery' })
    },
    Commas(x) {
      if (x) {
        if (Math.round(x) !== x && x > 0) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    SwalMes(Type, Title, Html) {
      this.$swal({
        icon: Type,
        title: Title,
        html: Html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    // Modal
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },
}
</script>

<style scoped>
/* html,
body {
  margin: 0;
  padding: 0;
} */
.form-css {
  padding: 5px 2px;
}
.bg-option {
  background: rgba(0, 0, 0, 0.2);
  color: #151515;
  text-shadow: #eee 1px 1px 0;
}
.rounded {
  border-radius: 0.25rem !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-fill .bg-btn {
  margin: 2.5px;
}
.cradit-total {
  background-color: rgb(0, 119, 255);
  padding: 5px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
}
.cradit {
  background-color: rgb(197, 74, 74);
  padding: 5px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
}
.bg-cradit {
  margin: auto;
  background-color: #fff;
  color: #000;
  width: 150px;
  font-size: 0.6rem;
  border-radius: 10px;
}
.fix-price {
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.219);
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 10px 10px 0 0;
  z-index: 1;
}
.cradit-total-after {
  background-color: rgb(172, 84, 2);
  padding: 5px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  /* border-radius: 0 10px 0 0; */
}
.condition-top {
  background-color: #d40000;
  border-radius: 10px 10px 0px 0px;
  padding: 5px;
  color: #fff;
}
.condition-body {
  background-color: #838383;
  border-radius: 0px 0px 10px 10px;
  color: #fff;
}
.btn-type3 {
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  border-radius: 25px;
  font-weight: 700;
  border: 0px;
}
.btn-type2 {
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  border-radius: 25px;
  border: 0px;
}
.btn-type1 {
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  border-radius: 25px;
  border: 0px;
}

.btnp:hover {
  background-color: #fff;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  background-size: 200% auto;
  transition: 0.5s;
}

.active,
.btnp:focus {
  background-color: #fff;
  color: rgb(0, 0, 0);
}

.tang-huay {
  background: rgba(43, 9, 9, 0.342);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.219);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.img-country {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.time {
  float: right;
  color: #fff;
  background: #d40000;
  border-radius: 7px;
  padding: 3px;
  text-align: center;
}
.topic {
  color: #d40000;
}
.order {
  border: 1px solid rgb(207, 207, 207);
  border-radius: 5px;
}
.balance {
  border: 1px solid rgb(255, 123, 123);
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  /* background-image: url(https://fn.dmpcdn.com/Components/HeaderMenu/images/red-defual-header.svg); */
  background-color: #fff;
  background-position: left bottom;
  border-radius: 10px;
  background-size: cover;
  position: relative;
  z-index: unset;
}

.header-bg {
  background-color: rgb(255, 255, 255);
  padding: 4px;
}
.header-bg-content {
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.219);
  border-radius: 10px;
}
.header-bg-content2 {
  background-image: url(/images/bg-header.svg);
  background-color: #fff;
  padding: 10px;
  background-size: cover;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.219);
  border-radius: 10px;
}

.YrVDG {
  font-size: 1.5rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(36, 36, 36);
}
@media (max-width: 574px) {
  .YrVDG {
    font-size: 1.2rem;
    font-weight: 700;
  }
}
.text-card {
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}
.container-spon {
  margin: auto;
  max-width: 640px;
}

.lotto {
  /* text-align: center; */
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
  padding: 0.3rem;
}
.key-pad .box__keyboard {
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
  padding: 8px;
  margin: 0px 15px;
}
.mmb-1 {
  margin-bottom: 4px;
}
.bg-side {
  background-color: #111;
  border-radius: 5px;
  padding: 2px;
  color: #fff;
}
.pdd {
  padding-right: 10px;
  padding-left: 10px;
}
hr {
  margin-top: -2px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(221, 221, 221, 0.75),
    rgba(0, 0, 0, 0)
  );
}
p {
  line-height: 0.7;
}
.btn-outline-red {
  color: #dc3545;
  background-color: rgba(255, 0, 0, 0.1);
  background-image: none;
  border-color: #dc3545;
}
.bg-danger {
  background-color: #dc3545 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
.bg-blue {
  background-color: #007bff !important;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.row-btn-tanghuay .btn div:first-child {
  margin-left: -11px;
  margin-top: -5px;
  margin-bottom: -6px;
  width: 80%;
  padding: 8px 5px;
  border-radius: 3px 0px 0px 3px;
  vertical-align: middle;
}
.row-btn-tanghuay .btn div:last-child {
  font-style: normal;
  text-align: center;
  width: 20%;
}
.row-btn-tanghuay .btn div:first-child {
  padding: 8px 5px 5px;
}
.bg-danger {
  background-color: #dc3545 !important;
}
.text-white {
  color: #fff !important;
}
@media screen and (max-width: 768px) {
  .btn-tanghuay {
    font-size: 12px !important;
    padding-right: 4px;
  }
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-tanghuay {
  line-height: 1;
}
</style>

<style lang="scss" scoped>
@import url('https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css');
@import '@core/scss/vue/pages/page-auth.scss';
.form-control {
    padding: 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0rem;
}
.style-1 {
  .otp-input-8-field {
    border-radius: 0;
    border: 1px solid #c50000;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
.col-4,
.col-sm-6 {
  padding: 0.18rem;
  max-width: 100%;
  flex-grow: 1;
}
.b-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed !important;
  top: 0;
  height: 100vh;
  width: 100%;
  max-width: 100% !important;
  outline: 0;
}
[dir] .btn-sm,
[dir] .btn-group-sm > .btn {
  padding: 4px;
  border-radius: 0.358rem;
}
</style>
